.mission_img {
    width: 100%;
    object-fit: contain;
    display: block;
}

.mission_img_block {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.mission_text_block {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.mission_text {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    width: 50%;
}

.mission_text:first-child {
    margin-right: 50px;
}

@media screen and (max-width: 1200px) {
    .mission_text {
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {
    .mission_text {
        font-size: 28px;
    }

    .mission_img_block {
        margin-bottom: 40px;
    }

    .mission_text_block {
        flex-direction: column;
    }

    .mission_text {
        width: 100%;
    }

    .mission_text:first-child {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 640px) {
    .mission_text {
        font-size: 14px;
    }

    .mission_img_block {
        margin-bottom: 20px;
    }

    .mission_text:first-child {
        margin-bottom: 20px;
    }
}