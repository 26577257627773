.pc_name {
    font-weight: 900;
    font-size: 16px;
    color: #73f9bc;
}

.pc_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pc_item {
    width: calc(100% / 4);
    padding: 0 40px;
}

.pc_links {
    display: flex;
}

.pc_link {
    display: block;
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
}

.pc_position {
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.pc_info {
    width: 256px;
    background-color: #000;
    padding: 9px 17px;
    margin-right: 35px;
}

.pc_img_block {
    max-width: 210px;
    width: 100%;
    margin-left: auto;
    position: relative;
    bottom: -40px;
}

@media screen and (max-width: 1600px) {
    .pc_info {
        width: 200px;
        padding: 7px;
        height: 100%;
    }

    .pc_img_block {
        max-width: 170px;
        left: 20px;
        top: 30px;
    }

    .pc_position {
        font-size: 12px;
    }

    .pc_item {
        overflow: hidden;
    }

    .pc_name {
        font-size: 14px;
    }
}

@media screen and (max-width: 1400px) {
    .pc_info {
        width: 186px;
    }

    .pc_name {
        font-size: 12px;
    }
}

@media screen and (max-width: 1200px) {
    .pc_item {
        width: calc(100% / 3);
    }

    .pc_info {
        width: 215px;
    }

    .pc_name {
        font-size: 14px;
    }

    .pc_item {
        padding: 0 40px;
    }
}

@media screen and (max-width: 900px) {
    .pc_info {
        width: 165px;
    }

    .pc_name {
        font-size: 10px;
    }

    .pc_position {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .pc_link {
        width: 15px;
        height: 15px;
        margin-right: 8px;
    }

    .pc_item {
        padding: 0 10px;
    }
}

@media screen and (max-width: 640px) {
    .pc_item {
        width: calc(100% / 2);
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .pc_img_block {
        max-width: unset;
        width: 100%;
        position: unset;
    }

    .pc_info {
        width: 100%;
        margin-right: 0;
        height: 100%;
    }

    .pc_name {
        font-size: 16px;
    }

    .pc_position {
        font-size: 14px;
        height: auto;
    }
}

@media screen and (max-width: 420px) {
    .pc_item {
        padding: 0 10px;
    }

    .pc_name {
        font-size: 14px;
    }

    .pc_position {
        font-size: 12px;
    }

}