.team_block {
    display: flex;
    justify-content: space-between;
}

.team_wrap {
    margin-bottom: 100px;
    margin-top: 100px;
}

.team_img_block {
    max-width: 210px;
    width: 100%;
    margin-left: auto;
    position: relative;
    bottom: -40px;
}

.team_img {
    width: 100%;
    object-fit: contain;
}

.team_info {
    width: 256px;
    background-color: #000;
    padding: 9px 17px;
    margin-right: 35px;
}

.team_name {
    font-weight: 900;
    font-size: 22px;
    color: #73f9bc;
}

.team_position {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 23px;
}

.team_links {
    display: flex;
}

.team_link {
    display: block;
    width: 33px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
}

@media screen and (max-width: 1600px) {
    .team_img_block {
        max-width: 170px;
    }

    .team_info {
        width: 185px;
    }

    .team_position {
        font-size: 12px;
    }
    
    .team_link {
        width: 25px;
        height: 25px;
    }
}

@media screen and (max-width: 1200px) {
    .team_img_block {
        max-width: unset;
        /* bottom: unset; */
    }

    .team_item {
        padding: 0 10px;
        width: calc((100% / 4) - 20px);
    }

    .team_info {
        width: 100%;
        margin-right: 0;
        padding: 7px;
    }

    .team_name {
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {
    .team_item {
        padding: 0 10px;
        width: calc((100% / 2) - 20px);
        margin-bottom: 30px;
    }

    .team_block {
        flex-wrap: wrap;
    }

    .team_wrap {
        margin-bottom: 100px;
        margin-top: 60px;
    }
}

@media screen and (max-width: 640px) {
    .team_item {
        padding: 0 5px;
        width: 50%;
        margin-bottom: 30px;
    }
    .team_info {
        margin-right: 0;
    }
    .team_name {
        font-size: 14px;
    }
    .team_block {
        flex-wrap: wrap;
    }
}