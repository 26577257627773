.audience_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.audience_wrap span {
    -webkit-text-stroke: 1px rgba(219,219,219,0.5);
    color: transparent;
    font-size: 50px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.55;
    margin-right: 20px;
}
.audience_wrap span.green {
    color: rgb(115, 249, 188);
    -webkit-text-stroke: 1px rgb(115, 249, 188);
    font-weight: 700;
}
@media only screen and (max-width: 1200px) {
    .audience_wrap span {
        font-size: 40px;
    }
}
@media only screen and (max-width: 976px) {
    .audience_wrap span {
        font-size: 30px;
    }
}
@media only screen and (max-width: 450px) {
    .audience_wrap span {
        font-size: 25px;
    }
}