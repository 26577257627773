.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(23, 22, 22, 0.4);
    z-index: 99999;
    display: block;
}

.popup_container {
    width: 600px;
    height: fit-content;
    max-height: calc(100vh - 200px);
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    background: #171616;
    border: 1px solid #73f9bc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
    overflow: hidden;
    overflow-y: scroll;
}

.popup_title {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #73f9bc;
}

.popup_abstract {
    font-size: 18px;
    white-space: pre-line;
}

.popup_abstract--mb {
    margin-bottom: 30px;
}

.popup_btn {
    background: #73f9bc;
    padding: 3px 10px;
    max-width: 200px;
    width: 100%;
    font-size: 18px;
    margin-top: 10px;
    transition: .4s;
}

.popup_btn:hover {
    background: #fff;
}

@media screen and (max-width: 640px) {
    .popup_container {
        width: 400px;
    }

    .popup_title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .popup_abstract {
        font-size: 14px;
    }

    .popup_abstract--mb {
        margin-bottom: 15px;
    }
}

@media screen and (max-width:450px) {
    .popup_container {
        width: 320px;
    }
}