.askCompany {
    background-color: #171616;
}
/*
.askCompany-block {
    background-color: rgb(97, 219, 251);
    border-radius: 25px;
    margin: 50px 30px;
    font-family: 'SpaceMono';
    text-align: center;
    color: #000;
}
.askCompany-block h1 {
    font-size: 38px;
    padding-top: 50px;
    font-weight: 700;
}
.askCompany-block h2 {
    font-size: 20px;
    padding: 20px 0;
}
.askCompany-block p {
    font-size: 14px;
    margin: 0;
}
.askCompany-block form {
    padding: 50px 0;
    display: flex;
    justify-content: space-around;
}
.askCompany-block form div{
    display: flex;
    justify-content: space-between;
    width: 48%;
}
.askCompany-block input {
    border: 1px solid #000;
    background: transparent;
    width: 48%;
    height: 50px;
    border-radius: 15px;
}
.askCompany-block input[type='text'],.askCompany-block input[type='email'] {
    padding-left: 10px;
}
.askCompany-block input[type='text']::placeholder,.askCompany-block input[type='email']::placeholder {
    font-size: 11px;
    word-spacing: -2px;
    color: #000;
}
.askCompany-block input[type='submit'] {
    font-size: 20px;
    transition: 0.5s;
    font-weight: 700;
    background-color: #fff;
    border-color: transparent;
}
.askCompany-block input[type='submit']:hover {
    background-color: #000;
    color: rgb(97, 219, 251);
} */
@media only screen and (max-width: 991px) {
    /* .askCompany-block {
        margin: 20px 0;
    }
    .askCompany-block h1 {
        font-size: 25px;
    }
    .askCompany-block h2 {
        font-size: 14px;
        padding: 10px 0;
    }
    .askCompany-block p {
        font-size: 11px;
    }
    .askCompany-block form {
        flex-direction: column;
        align-items: center;
        padding: 20px 0 50px;
    }
    .askCompany-block form div{
        width: 94%;
        padding-top: 20px;
    }
    .askCompany .container, .container-sm {
        max-width: 740px;
    } */
   
}
/* @media only screen and (max-width: 530px) {
    .askCompany-block h1 {
        font-size: 19px;
        padding-top: 20px;
    }
    .askCompany-block h2 {
        font-size: 13px;
        padding: 10px 0;
    }
    .askCompany-block p {
        font-size: 10px;
    }
    .askCompany-block form {
        padding: 20px 0 20px;
    }
    .askCompany-block form div{
        flex-direction: column;
        padding-top: 0;
    }
    .askCompany-block form div input{
        width: 100%;
        margin-top: 15px;
    }
    .askCompany-block input[type='submit'] {
        font-size: 17px;
    }
} */

@media screen and (max-width: 1000px) {
    .iframe_askcompany {
        height: 428px!important;   
    }
}

@media screen and (max-width: 900px) {
    .iframe_askcompany {
        height: 506px!important;
    }
}

@media screen and (max-width: 800px) {
    .iframe_askcompany {
        height: 582px!important;
    }
}

@media screen and (max-width: 700px) {
    .iframe_askcompany {
        height: 486px!important;
    }
}

@media screen and (max-width: 440px) {
    .iframe_askcompany {
        height: 642px!important;
    }
}