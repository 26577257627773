header{
    display: flex;
    position: fixed;
    justify-content: space-between;
    z-index: 555;
    width: 100%;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    height: 58px;
    margin: 50px auto 0;
}

.header_container {
    max-width: 1660px;
    padding: 0 80px;
}

header > div{width:78px;}
header img{height: 100%;}

@media screen and (max-width: 640px) {
    header {
        margin: 10px auto 0;
    }
}