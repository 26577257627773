.full_agenda_btn {
    margin-top: 30px;
    display: block;
    max-width: 400px;
    width: 100%;
    font-size: 30px;
    padding: 5px 0;
    background: #73f9bc;
    text-align: center;
    transition: .4s;
    margin-left: auto;
    color: #171616;
    margin-right: auto;
}

.full_agenda_btn:hover {
    background: #fff;
}

@media screen and (max-width: 640px) {
    .full_agenda_btn {
        max-width: 300px;
        font-size: 24px;
    }
}