ul.menu{
    display: flex;
    position: fixed;
    justify-content: space-between;
    width: 70%;
    background-color: rgba(28, 28, 28, 0.9);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(115, 249, 188);
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    margin: 0 auto;
    padding: 0;
}
ul.menu li{
    text-align: center;
}

.menu_pc {
    transition: .4s;
}

.menu_pc:hover {
    background:rgb(115, 249, 188);
}

ul.menu li a{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    width:125px;
    height: 58px;
}

.mobile-menu {
    display: none;
}

@media screen and (max-width: 1200px) {
    .menu_pc {
        display: none;
    }

    .mobile-menu {
        display: block;
        padding: 10px 20px;
        margin-left: auto;
    }

    .mobile-menu_img-block {
        width: 33px;
        height: 33px;
        margin-left: auto;
    }

    header > div {
        width: 78px;
        display: none;
    }

    ul.menu {
        width: 90%;
    }    
}