.agenda_title {
    text-transform: uppercase;
    padding-bottom: 0;
    margin-bottom: 20px;
}

.agenda_undertitle {
    font-size: 22px;
    margin-bottom: 10px;
    text-align: center;
}

.agenda_day {
    font-size: 30px;
    padding-bottom: 15px;
    border-bottom: 5px solid #73f9bc;
    width: 200px;
    margin: 0 auto;
    text-align: center;
}

.agenda_social {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 80px;
}

.agenda_block {
    margin-top: 30px;
}

.agenda_item {
    display: flex;
    width: 100%;
    padding: 20px;
    border: 1px solid #73f9bc;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 20px;
}

.agenda_info {
    display: flex;
    width: 55%;
}

.agenda_bio {
    display: flex;
}

.agenda_time {
    font-size: 24px;
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.agenda_topic {
    font-size: 26px;
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 510px;
    width: 100%;
}

.agenda_logo {
    object-fit: contain;
    margin: 0 auto;
    margin-right: 25px;
}

.agenda_photo {
    max-height: 100px;
    object-fit: contain;
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 7px;
}

.agenda_name {
    color: #73f9bc;
    font-weight: 400;
    font-size: 30px;
}

.agenda_position {
    font-size: 22px;
}

.popup_btn.agenda_btn {
    margin: unset;
    margin-left: auto;
    padding: 9px 10px;
    max-width: 230px;
    font-size: 23px;
}

.popup_btn.agenda_btn:hover {
    background: #73f9bc;
}

@media screen and (max-width: 1600px) {
    .agenda_time {
        margin-right: 0;
    }

    .agenda_logo {
        max-width: 140px;
        margin: 0 10px;
    }

    .agenda_name {
        font-weight: 400;
        font-size: 24px;
    }

    .speakers_btn.agenda_btn {
        font-size: 18px;
        max-width: 210px;
    }

    .agenda_photo {
        max-height: 70px;
        margin-right: 10px;
    }

    .agenda_topic {
        font-size: 20px;
        margin-right: 10px;
    }

    .agenda_position {
        font-size: 18px;
    }

    .agenda_bio {
        margin-right: 5px;
    }

    .popup_btn.agenda_btn {
        padding: 6px 10px;
        max-width: 210px;
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .agenda_item {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .agenda_info {
        margin-bottom: 20px;
        justify-content: space-between;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .agenda_info {
        width: 100%;
    }

    .agenda_bio {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .agenda_btn {
        grid-column-start: 3;
        grid-column-end: 4;
    }

    .popup_btn.agenda_btn {
        grid-column-start: 2;
        grid-column-end: 4;
        margin-top: auto;
        margin-bottom: auto;
    }

    .agenda_topic {
        font-size: 20px;
    }
}

@media screen and (max-width: 640px) {
    .agenda_item {
        position: relative;
    }

    .agenda_time {
        position: absolute;
        top: -31px;
        left: -21px;
        margin: 0;
        background: #fff;
        color: #171616;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
    }

    .agenda_topic {
        font-size: 16px;
    }

    .agenda_info {
        margin-top: 10px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
    }

    .agenda_logo {
        max-width: 100px;
        margin: 0;
        margin-left: auto;
    }

    .agenda_photo {
        max-height: 60px;
    }

    .agenda_bio {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .popup_btn.agenda_btn {
        grid-column-start: 1;
        grid-column-end: 4;
        max-width: 100%;
        margin-top: 20px;
        font-size: 16px;
    }

    .agenda_undertitle {
        font-size: 18px;
    }

    .agenda_day {
        font-size: 24px;
        padding-bottom: 5px;
    }

    .agenda_social {
        width: 60px;
    }
}

@media screen and (max-width: 640px) {
    .agenda_topic {
        font-size: 14px;
    }

    .agenda_logo {
        margin: 4px 0;
        margin-left: auto;
    }

    .agenda_name {
        font-size: 20px;
    }

    .agenda_position {
        font-size: 14px;
    }
}