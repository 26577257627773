.speakers_from .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.speakers_from .logo_item{
    width: 17%;
    margin: 0 20px;
}

@media screen and (max-width: 1050px) {
    .speakers_from .logo_item{
        width: 30%;
        margin: 0 10px;
    }
}

@media screen and (max-width: 640px) {
    .speakers_from .logo_item{
        width: 25%;
    }
}