.become .container{
    display: flex;
    justify-content: space-around;
}
.become_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    width: 100%;
}
.become_title{
    font-size: 28px;
    /* text-align: center; */
    text-transform: uppercase;
}
.become_button{
    display: block;
    width: 100%;
    margin-top: 26px;
    text-decoration: none;
    text-align: center;
    padding: 5px 0;
    font-size: 30px;
    line-height: 1.2;
    color: #171616;
    background: rgb(115, 249, 188);
    transition: .4s;
}

.become_button--sponsorship {
    background: #171616;
    border: 1px solid #fff;
    color: #fff;
    font-size: 22px;
    padding-left: 3px 0;
}

.become_button:hover {
    background: #fff;
}

.become_button--sponsorship:hover {
    background: #fff;
    color: #171616;
}

@media screen and (max-width: 1200px) {
    .become_item {
        margin: 0 10px;
    }

    .become_title {
        font-size: 20px;
    }

    .become_button {
        margin-top: 16px;
    }
}

@media screen and (max-width: 640px) {
    .become .container{
        flex-direction: column;
    }
    .become_item {
        margin: 0 auto 30px;
    }
    .become_button{
        padding:8px 25px;
    }
}