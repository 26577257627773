.numbers{
    padding: 100px 0;
    background: #171616;
    background-image: url('../../img/numbers_bcg.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.numbers > .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.numbers .numbers_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 20%;
    max-width: 325px;
}
.numbers_item-numb{
    font-weight: 500;
    font-size: 70px;
    line-height: 1;
    color: #ffffff;
    margin: 0;
}
.numbers_hr{
    display: block;
    width: 310px;
    height: 2px;
    background: #ffffff;
    margin: 10px 0 20px;
}
.numbers_item-text{
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 1.2;
    color: #ffffff;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    .numbers_item-numb {
        font-size: 55px;
    }

    .numbers .numbers_item{
        width: calc(90% / 3);
        margin: 0 20px;
    } 

    .numbers_item-text {
        font-size: 22px;
        text-align: center;
    }

    .numbers_hr {
        width: 100%;
    }

    .numbers {
        padding: 80px 0;
    }
}

@media screen and (max-width: 900px) {
    .numbers_item {
        margin: 30px 20px;
    }

    .numbers > .container{
        flex-direction: column;
    }

    .numbers .numbers_item{
        width: 100%;
        max-width: 500px;
        margin: 30px 0;
    }
}

