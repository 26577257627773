.track{
    max-width: calc((100% - 360px) / 2);
    width: 100%;
    border: solid 1px #fff;
}
.track.active {
    border: solid 1px #73f9bc;
}
.trackTitle{
    font-weight: 900;
    font-size: 60px;
    line-height: 1.2;
    text-align: center;
    color: #FFFFFF;
    margin: 10px 0;
    padding: 0 15px;
}
.trackSubtitle{
    height: 2em;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    padding: 0 15px;
}
.trackPrice{
    font-weight: 400;
    font-size: 70px;
    line-height: 1.2;
    text-align: center;
    color:#171616;
    background: #FFFFFF;
    margin: 50px 0;
    padding: 20px 0;
}
.trackButton {
    transition: .4s;
}

.trackButton:hover {
    background: #fff;
} 

.trackPrice.active{
    background: #73f9bc;
}
.trackDesc{
    padding: 0 15px;
    margin-bottom: 20px;
}
.trackDesc_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.trackDesc_item img{
    display: block;
    width:20px;
    height:100%;
    margin-right: 15px;
}
.trackDesc_item p{
    width: 380px;
    font-weight: 390;
    font-size: 21px;
    line-height: 1.2;
    color: #FFFFFF;
    margin: 0;
}
.trackDesc .trackDesc_first,
.trackDesc .trackDesc_fourth,
.trackDesc .trackDesc_fifth{
    opacity: 0.5;
    filter: grayscale(1);
}
.trackDesc.active .trackDesc_first,
.trackDesc.active .trackDesc_second,
.trackDesc.active .trackDesc_third,
.trackDesc.active .trackDesc_fourth,
.trackDesc.active .trackDesc_fifth{
    opacity: 1;
    filter: grayscale(0);
}

.trackPrice.trackPrice--active {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #73f9bc;
    color: #171616;
}

.packages_price_wrap .packages_price {
    font-size: 60px;
    margin-right: 20px;
}

.packages_price {
    font-weight: 500;
    font-size: 70px;
    text-align: center;
    z-index: 2;
}

.packages_price--desc  {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    margin-right: 20px;
    margin-left: 20px;
}

.packages_price--full_desc {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
}

.packages_price--full {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    line-height: 1;
}

.packages_price--full_block {
    margin-left: 10px;
}

.packages_price--full_block::before {
    content: '';
    position: absolute;
    top: -12px;
    right: 27px;
    width: 2px;
    height: 65px;
    background: #171616;
    transform: rotate(51deg);
}

@media screen and (max-width: 1600px) {
    .trackTitle{
        font-size: 45px;
    }
    .trackSubtitle{
        font-size: 18px;
    }
    .trackPrice{
        font-size: 64px;
        margin: 30px 0;
        padding: 15px 0;
    }

    .packages_price {
        font-size: 64px;
    }

    .trackDesc_item p{
        font-size: 18px;
    }

    .trackDesc_item p{
        width: 330px;
    }
}

@media screen and (max-width: 1320px) {
    .track{
        max-width: calc((100% - 100px) / 2);
    }

    .trackTitle {
        font-size: 35px;
    }

    .trackSubtitle {
        font-size: 12px;
    }

    .trackPrice {
        font-size: 64px;
        margin: 20px 0;
        padding: 5px 0;
    }

    .trackDesc {
        padding: 0px 10px;
    }
    
    .trackDesc_item p {
        font-size: 16px;
    }
    
    .trackDesc_item img {
        width: 16px;
    }

    a.trackButton {
        margin: 20px auto 20px;
        font-size: 20px;
    }
}

@media screen and (max-width: 950px) {
    .track {
        max-width: calc((100% - 60px) / 2);
        margin-bottom: 40px;
    }

    .track_wrap {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 640px) {
    .track {
        max-width: 100%;
    }

    .trackPrice {
        font-size: 44px;
    }

    .packages_price {
        font-size: 44px;
    }

    .track_wrap {
        justify-content: center;
    }

    .trackDesc {
        padding: 0px 30px;
        width: 300px;
    }

    .trackTitle {
        font-size: 30px;
    }
}