.main_wrap {
    padding-top: 94px;
    height: 100vh;
    max-height: 870px;
    background: #73f9bc;
    color: #171616;
    display: flex;
    align-items: center;
}

.main_block {
    margin-top: 35px;
}
.main_title {
    margin-bottom: 54px;
    position: relative;
}

.main_descr {
    font-size: 50px;
    margin-bottom: 17px;
}

.main_descr-data {
    margin-bottom: 0;
}

.main_btn:hover {
    background-color: #fff;
    color: #000;
}

.main_img {
    position: absolute;
    top: 0;
    right: -15rem;
    z-index: -1;
}

.main_wrap {
    position: relative;
}

.main_wrap::after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../img/creative.gif');
    max-width: 700px;
    width: 100%;
    height: auto;
    position: absolute;
    max-height: 930px;
    height: 100%;
    top: 130px;
    right: 0;
}

.main_content {
    max-width: 60%;
    z-index: 10;
}

.main_btns_wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    max-width: 640px;
}

.main_btn {
    background: hsl(0, 2%, 9%);
    width: 307px;
    color: #fff;
    display: block;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 10px;
    transition: .4s;
    font-weight: 600;
    font-size: 25px;
}

.main_btn--share {
    background: transparent;
    color: #171616;
    border: 1px solid #171616;
    width: 100%;
}

.main_copy_btn_block {
    width: 307px;
    position: relative;
}

.main_copy_text {
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: 400;
}

.main_btns_wrap--next {
    margin-top: 20px!important;
}

.st-inline-share-buttons {
    background-position: center center;
    border: 2px solid #fff;
    width: 280px;
    height: 60px;
    text-decoration: none;
    width: 45%;
    text-transform: uppercase;
    text-align: center;
    padding: 12px 10px;
    transition: .4s;
    font-weight: 600;
    font-size: 25px;
    line-height: 1;
}

.st-inline-share-buttons:hover {
    border: 1px solid #171616;
    background: #171616!important;
}

.st-inline-share-buttons .st-label {
    color: #fff;
    font-size: 25px;
    font-family: 'Oswald', sans-serif;
    line-height: 1.55;
    font-weight: 600;
    text-transform: uppercase;
    display: block!important;
}

.st-inline-share-buttons .st-btn {
    width: 100%;
    display: flex!important;
    justify-content: space-evenly;
    align-items: center;
    height: 100%!important;
}

.st-inline-share-buttons img {
    top: unset!important;
    height: initial!important;
}

.st-btn:hover {
    opacity: unset!important;
    top: unset!important;
}

.st-btn[data-network='sharethis'] {
    background: transparent!important;
}

@media screen and (min-width: 1921px) {
    .main_wrap::after {
        right: 17%;
    }
}

@media screen and (max-width: 1200px) {
    .main_wrap::after {
        max-height: 500px;
        right: -140px;
    }

    .main_wrap {
        max-height: 670px;
    }

    .main_btn--first {
        width: 45%;
    }

    .main_copy_btn_block {
        width: 45%;
    }

    .main_descr {
        font-size: 40px
    }

    .main_btns_wrap {
        margin-top: 50px;
    }
}

@media screen and (max-width: 900px) {
    .main_wrap::after {
        right: -190px;
    }
}

@media screen and (max-width: 800px) {
    h1 {
        font-size: 33px;
        margin-bottom: 30px;
    }

    .main_descr {
        font-size: 30px;
    }
}

@media screen and (max-width: 640px) {
    .main_wrap {
        padding-top: 54px;
        align-items: unset;
        max-height: 870px;
    }

    .main_wrap::after {
        right: 48%;
        transform: translateX(50%);
        top: 380px;
        max-height: 520px;
    }

    .main_content {
        max-width: 100%;
        z-index: 10;
    }

    .main_title {
        margin-bottom: 35px;
    }

    .main_btns_wrap {
        margin-top: 20px;
    }

    h1 {
        font-size: 35px;
    }

    .main_descr {
        font-size: 26px;
    }

    .container.main_block {
        margin: 40px auto 40px;
    }

    .main_btn {
        font-size: 18px;
        height: 45px;
    }

    .st-inline-share-buttons .st-label {
        font-size: 18px;
    }

    .st-inline-share-buttons {
        height: 45px;
    }
}

@media screen and (max-width: 420px) {
    .main_wrap {
        padding-top: 54px;
        align-items: unset;
    }

    h1 {
        font-size: 28px;
    }

    .container.main_block {
        margin: 40px auto 0px;
    }

    .main_btn {
        padding: 5px 10px;
    }

    .main_wrap {
        max-height: 720px;
    }

    .main_wrap::after {
        max-height: 350px;
        right: 50%;
        top: 368px;
    }
}