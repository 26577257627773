/* .enterprise {
    background-color: #000;
}
.enterprise-block {
    background-color: rgb(97, 219, 251);
    border-radius: 25px;
    margin: 50px 30px;
    font-family: 'SpaceMono';
    text-align: center;
    color: #000;
}
.enterprise-block h1 {
    font-size: 38px;
    padding-top: 50px;
    font-weight: 700;
}
.enterprise-block h2 {
    font-size: 20px;
    padding: 20px 0;
}
.enterprise-block p {
    font-size: 14px;
    margin: 0;
}
.enterprise-block form {
    padding: 20px 0 50px;
    display: flex;
    justify-content: space-around;
}
.enterprise-block form div{
    display: flex;
    justify-content: space-between;
    width: 47%;
}
.enterprise-block input {
    border: 1px solid #000;
    background: transparent;
    width: 47%;
    height: 45px;
    border-radius: 15px;
}
.enterprise-block input[type='text'],.enterprise-block input[type='email'] {
    padding-left: 10px;
}
.enterprise-block input[type='text']::placeholder,.enterprise-block input[type='email']::placeholder {
    font-size: 13px;
    word-spacing: -2px;
    color: #000;
}
.enterprise-block input[type='submit'] {
    font-size: 20px;
    transition: 0.5s;
    font-weight: 700;
    background-color: #fff;
    border-color: transparent;
}
.enterprise-block input[type='submit']:hover {
    background-color: #000;
    color: rgb(97, 219, 251);
}
@media only screen and (max-width: 991px) {
    .enterprise-block {
        margin: 20px 0;
    }
    .enterprise-block h1 {
        font-size: 25px;
    }
    .enterprise-block h2 {
        font-size: 14px;
        padding: 10px 0;
    }
    .enterprise-block form {
        flex-direction: column;
        align-items: center;
        padding: 20px 0 50px;
    }
    .enterprise-block form div{
        width: 94%;
        padding-top: 20px;
    }
    .enterprise .container, .container-sm {
        max-width: 740px;
    }
}
@media only screen and (max-width: 530px) {
    .enterprise-block h1 {
        font-size: 19px;
        padding-top: 20px;
    }
    .enterprise-block h2 {
        font-size: 13px;
        padding: 10px 0;
    }
    .enterprise-block form {
        padding: 0 0 20px;
    }
    .enterprise-block form div{
        flex-direction: column;
        padding-top: 0;
    }
    .enterprise-block form div input{
        width: 100%;
        margin-top: 15px;
    }
    .enterprise-block input[type='submit'] {
        font-size: 17px;
    }
} */

.iframe_enterprise .bubble-element.clickable-element {
    background: rgb(97, 219, 251)!important;
    color: #000!important;
    border: rgb(97, 219, 251)!important;
}

@media screen and (max-width: 883px) {
    .iframe_enterprise {
        height: 450px!important;
    }
}

@media screen and (max-width: 520px) {
    .iframe_enterprise {
        height: 500px!important;
    }
}

@media screen and (max-width: 464px) {
    .iframe_enterprise {
        height: 510px!important;
    }
}

@media screen and (max-width: 419px) {
    .iframe_enterprise {
        height: 610px!important;
    }
}

#enterprise_btn {
    background: none rgb(97, 219, 251)!important;
    border: 1px solid rgb(97, 219, 251)!important;
    color: #000;
}