.review_block {
    display: flex;
    flex-wrap: wrap ;
}

.reviews_item{
    width: calc((100% - 20px) / 2);
    background: #000;
    margin-bottom: 30px;
    margin-right: 10px;
}
.reviews_item-info_wrap{
    display: flex;
    align-items: stretch;
    height: 210px;
}
/* bottom left */
.reviews_item-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-end ;
    width: 37%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    padding: 10px;
}
.reviews_item_name{
    font-size: 20px;
    font-weight: 900;
}
.reviews_item_position{
    font-size: 18px;
}
/* bottom right */
.reviews_item-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 63%;
    padding: 10px;
}
.reviews_item-content:before{
    content:'"';
    display: block;
    position: absolute;
    font-size: 300px;
    font-weight: 500;
    margin-top: 50px;
    color:#ffffff66;
}
.reviews_title{
    font-weight: 500;
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    .reviews_title{
        font-size: 14px;
    }
}

@media screen and (max-width: 900px) {
    .reviews_item {
        width: 100%;
        margin-bottom: 30px;
    }
    .reviews_item_name{font-size: 18px;}
    .reviews_item_position{font-size: 14px;}
    .reviews_title{font-size: 16px;}
}

@media screen and (max-width: 640px) {
    .reviews_title{
        font-size: 12px;
    }
    .reviews_item_name{
        font-size: 16px;
    }
}