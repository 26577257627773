div{position: relative;}

.container{
    max-width: 1660px;
    margin: 100px auto;
    padding: 0 80px;
}

.container--full{
    margin: 100px auto;
}

h1{
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 1.2;
    color: #171616;
    margin:0;
    z-index: 5;
}
h2{
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 1;
    color: #fff;
    margin:0;
}
h3{
    text-align: center;
    font-weight: 900;
    font-size: 70px;
    line-height: 100px;
    color: #73f9bc;
    margin:0;
    margin-top: 100px;
    line-height: 1.4;
}
a.button{
    display: block;
    width:315px;
    padding: 15px 0;
    margin-top: 120px;
    font-family: Ubuntu;
    font-weight: normal;
    text-decoration: none;
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
    color: #171616;
    background: rgb(115, 249, 188);
}
a.trackButton{
    display: block;
    width:80%;
    padding: 5px 0;
    margin: 0 auto 20px;
    font-family: Ubuntu;
    text-decoration: none;
    text-align: center;
    font-size: 28px;
    line-height: 1.2;
    color: #171616;
    background: rgb(115, 249, 188);
}
iframe {
    border: none;
}

main {
    overflow-x: hidden;
}
.social_link{
    display: block;
    width: 20px;
    height: 20px;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
}
.social_tw{
    background: url('./img/footer/twitter.svg');
}
.social_ln{
    background: url('./img/footer/linkedin.svg');
}
.social_fb{
    background: url('./img/footer/facebook.svg');
}
.social_website{
    background: url('./img/footer/website.svg');
}
.social_mail{
    background: url('./img/footer/mail.svg');
}
.social_github {
    background: url('./img/footer/github.svg');
}
.social_yb {
    background: url('./img/footer/youtube.svg');
}
.social_inst {
    background: url('./img/footer/instagram.svg');
}

@media screen and (max-width:1600px) {
    h1{
        font-size: 69px;
    }
}

@media screen and (max-width: 1400px) {
    h1 {
        font-size: 58px;
    }
}

@media screen and (max-width: 1200px) {
    h1 {
        font-size: 40px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width:900px) {
    .container{
        margin: 40px auto 100px;
        padding: 0 20px;
    }
    
    .container--full{
        margin: 40px auto 100px;
    }

    h3{
        font-size: 40px;
        margin-top: 100px;
    }
}

/*  */
.logo_item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.logo_item img{
    transition: filter .7s ease-in-out;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
}
.logo_item img:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
}
@media screen and (max-width: 640px) {
    .logo_item {
        width: calc(90% / 4);
    }
}
@media screen and (max-width: 440px) {
    .logo_item {
        width: calc(90% / 2);
    }
}