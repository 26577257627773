.partners .container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.partners a {
    max-width: 280px;
    padding: 20px;
}

@media screen and (max-width: 1200px) {
    .partners a {
        max-width: 150px;
    }
}

@media screen and (max-width: 980px) {
    .partners .container {
        justify-content: center;
        flex-wrap: wrap;
    }

    .partners a {
        padding: 10px;
    }
}
