.footer_wrap {
    position: relative;
    background: #171616;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.footer_geekle_logo {
    max-width: 104px;
    height: auto;
    width: 100%;
    margin-bottom: 28px;
}

.footer_links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_link_block {
    display: flex;
}

.footer_link_img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.footer_link {
    text-decoration: none;
    margin-right: 13px;
}
.footer,
.footer_contact {
    color:#fff;
}
.footer a {
    text-decoration: none;
}

.footer_link:last-child {
    margin-right: 0;
}

.footer_address {
    font-weight: 500;
    font-size: 18px;
}

.footer_contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_docs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_contact {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    transition: .4s;
    margin-bottom: 7px;
}

.footer_contact:last-child {
    margin-bottom: 0;
}

.footer_contact:hover {
    color: #0259D8;
}

.footer_docs_item {
    font-weight: 500;
    font-size: 18px;
    color: #73F9BC;
    transition: .4s;
}

.footer_docs_item:hover {
    color: #0259D8;
}

@media screen and (max-width: 900px) {
    .footer {
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .footer_links {
        flex-direction: row;
    }

    .footer_item {
        width: 100%;
    }

    .footer_links {
        flex-direction: column;
        justify-content: space-between;
        order: -2;
    }

    .footer_address {
        position: absolute;
        top: 245px;
        left: 20px;
    }

    .footer_contacts {
        text-align: right;
        align-items: flex-end;
        margin-top: 60px;
    }

    .footer_docs {
        text-align: center;
        margin-top: 20px;
        order: -1;
    }
}

@media screen and (max-width: 460px) {
    .footer_address {
        font-size: 12px;
    }

    .footer_docs_item, .footer_contact {
        font-size: 14px;
    }
}