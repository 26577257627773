.videoPresent_item{
    width: calc((100% - 40px) / 2);
    background: #000;
    margin-bottom: 30px;
}
.videoPresent_item-title_wrap{
    display: flex;
    align-items: center;
    background: rgb(115, 249, 188);
}
.videoPresent_item-info_wrap{
    display: flex;
    align-items: stretch;
    height: 100%;
    position: relative;
}
.video_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
/* bottom left */
.videoPresent_item-video{
    width: 55%;
}
.videoPresent_item-info:before{
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
}
.videoPresent_item_name{
    font-size: 20px;    
    font-weight: 900;
}
.videoPresent_item_position{
    font-size: 18px;
    margin-bottom: 5px;
}
.videoPresent_social_link{
    display: flex;
    margin-bottom: 10px;
}
.videoPresent_social_link a:nth-child(2n){
    margin:0 10px;
}
/* bottom right */
.videoPresent_item-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
    padding: 10px;
}
.videoPresent_title{
    font-weight: 500;
    font-size: 18px;
    margin-top: 5px;
}
.videoPresent_buttons{
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between;
}
.videoPresent_button-book{
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 8px 30px;
    font-size: 20px;
    color: #171616;
    background: rgb(115, 249, 188);
}
.videoPresent_item-video img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.videoPresent_company_logo {
    max-width: 170px;
    width: 100%;
    object-fit: contain;
}

.videoPresent_button-learnMore{
    font-size: 20px;
    text-decoration: underline;
}
.videoPresent_item-play {
    width: 50px;
    height: 50px;
    background-image: url('../../../img/play.png');
    background-size: contain;
    background-color: unset;
    background-repeat: no-repeat;
}
.videoPresent_item-background {
    background: #c4c4c4;
    opacity: .4;
}

.speakers_track {
    position: absolute;
    top: 0;
    right: -20px;
    background: #73f9bc;
    color: #171616;
    font-size: 14px;
    padding: 0 20px;
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
    font-size: 16px;
    max-width: 100px;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    z-index: 1;
}

.video_btn {
    margin: 10px auto 0;
    font-size: 24px;
    text-transform: uppercase;
    background: #73f9bc;
    max-width: 300px;
    width: 100%;
    padding: 5px 20px;
    display: block;
}

.video_btn:hover {
    background: #fff;
}

.videoPresent_video_2 {
    display: flex;
    flex-direction: column;
}

.videoPresent_item-video.videoPresent_item-video2:first-child {
    margin-bottom: 95px;
}

.videoPresent_item-video2 {
    width: 100%;
    max-height: 180px;
}

.videoPresent_item_name2 {
    font-size: 14px;
}

.videoPresent_item_position2 {
    font-size: 14px;
}

.videoPresent_company_logo2 {
    max-height: 40px;
    object-fit: contain;
    width: unset;
    max-width: unset;
} 

.videoPresent_item-content {
    max-width: 330px;
    width: 100%; 
}

.video_popup_block {
    margin: 20px 0;
}

@media screen and (max-width: 1400px) {
    .videoPresent_item-content {
        max-width: 250px;
    }
}

@media screen and (max-width: 1200px) {
    .videoPresent_item-content {
        max-width: 220px;
    }
}

@media screen and (max-width: 1100px) {
    .videoPresent_item-content {
        max-width: 180px;
    }
}

@media screen and (max-width: 1000px) {
    .videoPresent_item-content {
        max-width: 160px;
    }

    .videoPresent_item-video.videoPresent_item-video2:first-child {
        margin-bottom: 145px;
    }
}

@media screen and (max-width: 900px) {
    .videoPresent_item {
        width: 48%;
        margin-bottom: 30px;
    }
    .videoPresent_item-info_wrap{
        flex-direction: column;
    }
    .videoPresent_item-video{width:100%;}
    .videoPresent_item-content{width:100%;}

    .videoPresent_item-content {
        max-width: unset;
    }

    .videoPresent_video_2 {
        flex-direction: row;
    }

    .videoPresent_item-video.videoPresent_item-video2:first-child {
        margin-bottom: 0;
    }

    .videoPresent_company_logo {
        max-width: fit-content;
    }

    .video_info_block {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .video_info_item2 {
        grid-column-start: 2;
        grid-column-end: 2;
    }

    .video_info_item1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
    }

    .video_popup_block {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
    }
}

@media screen and (max-width: 640px) {
    .videoPresent_item {
        width: 100%;
    }

    .video_btn {
        font-size: 20px;
        max-width: 200px;
        padding: 2px 10px;
    }

    .video_info_item {
        max-width: 130px;
    }

    .videoPresent_company_logo {
        max-height: 60px;
    }
}
