.cloud_react-logo {
    max-width: 80px;
    height: auto;
    animation: logo-rotate 10s infinite linear;
    position: absolute;
    top: 0;
    left: 42%;
}

.whatsNew-cloud p:hover {
    transform: scale(1.1);
}

@keyframes logo-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.whatsNew-cloud {
    position: relative;
    z-index: 100;
    height: 500px;
}
.whatsNew-cloud p {
    position: absolute;
    transition: 1s ease;
}
.whatsNew-cloud p{font-family: Ubuntu;}
.whatsNew-cloud p.item-1 {
    color: rgb(115, 249, 188);
    font-size: 60px;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    left: 0;
}
.whatsNew-cloud p.item-2 {
    color: #dbdbdb;
    font-size: 70px;
    line-height: 1.25;
    font-weight: 400;
    right: 0;
    top: 25px;
}
.whatsNew-cloud p.item-3 {
    color: #dbdbdb;
    font-size: 40px;
    line-height: 1.25;
    font-weight: 400;
    left: 3%;
    top: 90px;
}
.whatsNew-cloud p.item-4 {
    color: rgb(115, 249, 188);
    font-size: 60px;
    line-height: 1.35;
    font-weight: 400;
    left: 56%;
    top: 35px;
}
.whatsNew-cloud p.item-5 {
    color: rgb(115, 249, 188);
    font-size: 50px;
    line-height: 1.35;
    font-weight: 400;
    top: 150px;
    left: 20px;
}
.whatsNew-cloud p.item-6 {
    color: #dbdbdb;
    font-size: 70px;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 120px;
    left: 84%;
}
.whatsNew-cloud p.item-7 {
    color: #fff;
    font-size: 50px;
    line-height: 1.25;
    font-weight: 400;
    top: 210px;
    left: 0;
}
.whatsNew-cloud p.item-8 {
    color: #dbdbdb;
    font-size: 80px;
    line-height: 1.25;
    font-weight: 400;
    top: 185px;
    left: 47%;
}
.whatsNew-cloud p.item-9 {
    color: rgb(115, 249, 188);
    opacity: 0.5;
    font-size: 55px;
    line-height: 1.25;
    font-weight: 400;
    top: 138px;
    left: 59%;
}
.whatsNew-cloud p.item-10 {
    color: #dbdbdb;
    font-size: 55px;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 260px;
    left: 3%;
}
.whatsNew-cloud p.item-11 {
    color: #dbdbdb;
    font-size: 60px;
    line-height: 1.35;
    font-weight: 400;
    opacity: 0.7;
    top: 350px;
    right: 0;
}
.whatsNew-cloud p.item-12 {
    color: rgb(115, 249, 188);
    font-size: 40px;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 320px;
    left: 1%;
}
.whatsNew-cloud p.item-13 {
    color: rgb(115, 249, 188);
    font-size: 80px;
    line-height: 1.35;
    font-weight: 400;
    top: 250px;
    left: 60%;
}
.whatsNew-cloud p.item-14 {
    color: #dbdbdb;
    font-size: 80px;
    line-height: 1.35;
    font-weight: 400;
    top: 350px;
    left: 11%;
}
.whatsNew-cloud p.item-15 {
    color: #dbdbdb;
    font-size: 60px;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 415px;
    left: 41%;
}
.whatsNew-cloud p.item-16 {
    color: rgb(115, 249, 188);
    font-size: 30px;
    line-height: 1.25;
    font-weight: 400;
    top: 450px;
    left: 0;
}
.whatsNew-cloud p.item-17 {
    color: #dbdbdb;
    font-size: 40px;
    line-height: 1.25;
    font-weight: 400;
    top: 490px;
    left: 58%;
}
.whatsNew-cloud p.item-18 {
    color: rgb(115, 249, 188);
    font-size: 50px;
    line-height: 1.25;
    font-weight: 700;
    top: 110px;
    left: 48%;
}
.whatsNew-cloud p.item-19 {
    color: #dbdbdb;
    font-size: 50px;
    line-height: 1.25;
    font-weight: 400;
    top: 353px;
    left: 58%;
}

@media only screen and (max-width: 1450px) {
    .whatsNew-cloud p.item-1 {
        font-size: 50px;
    }
    .whatsNew-cloud p.item-2 {
        font-size: 60px;
    }
    .whatsNew-cloud p.item-3 {
        left: 2%;
    }
    .whatsNew-cloud p.item-4 {
        left: 54%;
    }
    .whatsNew-cloud p.item-5 {
        font-size: 40px;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 50px;
        left: 85%;
        top: 97px;
    }
    .whatsNew-cloud p.item-7 {
        left: 3%;
    }
    .whatsNew-cloud p.item-8 {
        left: 53%;
        font-size: 70px;
        top: 197px;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 50px;
    }
    .whatsNew-cloud p.item-12 {
        left: -1%;
        font-size: 40px;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 60px;
        left: 62%;
        top: 280px;
    }
    .whatsNew-cloud p.item-14 {
        left: 0%;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 55px;
        left: 44%;
    }
    .whatsNew-cloud p.item-17 {
        font-size: 24px;
        left: 71%;
    }
    .whatsNew-cloud p.item-18 {
        top: 140px;
        left: 46%;
    }
    .whatsNew-cloud p.item-19 {
        top: 360px;
        left: 46%;
    }
}
@media only screen and (max-width: 1250px) {
    .whatsNew {
        padding-top: 0;
    }
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 62%;
    }
    .cloud_react-logo {
        left: 50%;
    }
    
    .whatsNew-cloud p.item-1 {
        font-size: 50px;
        left: 15px;
    }
    
    .whatsNew-cloud p.item-2 {
        font-size: 50px;
    }

    .whatsNew-cloud p.item-3 {
        font-size: 30px;
        left: 3%;
    }

    .whatsNew-cloud p.item-4 {
        left: 53%;
        top: 60px;
    }

    .whatsNew-cloud p.item-6 {
        left: 85%;
    }

    .whatsNew-cloud p.item-5 {
        font-size: 40px;
        left: 54px;
    }

    .whatsNew-cloud p.item-7 {
        top: 200px;
        left: 0;
    }

    .whatsNew-cloud p.item-8 {
        left: 56%;
        font-size: 38px;
        top: 232px;
    }

    .whatsNew-cloud p.item-10 {
        font-size: 45px;
        left: 40px;
    }

    .whatsNew-cloud p.item-11 {
        font-size: 24px;
    }

    .whatsNew-cloud p.item-13 {
        font-size: 40px;
        left: 72%;
    }

    .whatsNew-cloud p.item-15 {
        font-size: 45px;
        left: 50%;
    }

    .whatsNew-cloud p.item-17 {
        left: 60%;
    }

    .whatsNew-cloud p.item-18 {
        top: 206px;
        right: 0;
        left: unset;
    }
}
@media only screen and (max-width: 1100px) {
    .cloud_react-logo {
        left: 36%;
        width: 60px;
        top: 20px;
    }
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 46%;
    }
    .whatsNew .whatsNew-title h2 {
        font-size: 35px;
        top: 25px;
    }
    .whatsNew-cloud p.item-1 {
        font-size: 30px;
        left: 50px;
    }
    .whatsNew-cloud p.item-2 {
        left: 58%;
        top: 0px;
    }
    .whatsNew-cloud p.item-3 {
        left: 0%;
        top: 50px;
        font-size: 18px;
    }
    .whatsNew-cloud p.item-4 {
        font-size: 40px;
        left: 39%;
        top: 40px;
    }
    .whatsNew-cloud p.item-5 {
        font-size: 35px;
        top: 100px;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 36px;
        left: 61%;
        top: 63px;
    }
    .whatsNew-cloud p.item-7 {
        font-size: 30px;
        left: 0%;
        top: 150px;
    }
    .whatsNew-cloud p.item-8 {
        font-size: 43px;
        top: 170px;
        right: 10%;
        left: unset;
    }
    .whatsNew-cloud p.item-9 {
        font-size: 25px;
        left: 80%;
        top: 70px;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 38px;
        left: 30px;
        top: 220px;
    }
    .whatsNew-cloud p.item-11 {
        top: 320px;
    }
    .whatsNew-cloud p.item-12 {
        left: 0;
        font-size: 36px;
        top: 270px;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 30px;
        top: 220px;
    }
    .whatsNew-cloud p.item-14 {
        font-size: 60px;
        top: 310px;
    }
    .whatsNew-cloud p.item-15 {
        left: unset;
        right: 3%;
        top: 367px;
    }
    .whatsNew-cloud p.item-16 {
        top: 410px;
    }
    .whatsNew-cloud p.item-17 {
        font-size: 20px;
        left: unset;
        right: 10%;
        top: 450px;
    }
    .whatsNew-cloud p.item-18 {
        top: 106px;
    }
    .whatsNew-cloud p.item-19 {
        top: 320px;
    }
}
@media only screen and (max-width: 900px) {
    .whatsNew-cloud p.item-1 {
        font-size: 25px;
    }
    .whatsNew-cloud p.item-2 {
        font-size: 43px;
        top: 5px;
    }
    .whatsNew-cloud p.item-4 {
        top: 30px;
        left: 43%;
        font-size: 30px;
    }
    .whatsNew-cloud p.item-5 {
        font-size: 30px;
        left: 50px;
        top: 70px;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 36px;
        left: 61%;
        top: 100px;
    }
    .whatsNew-cloud p.item-7 {
        top: 150px;
        left: 10px;
    }
    .whatsNew-cloud p.item-8 {
        left: 41%;
        font-size: 33px;
        top: 200px;
    }
    .whatsNew-cloud p.item-9 {
        left: 72%;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 30px;
        left: 30px;
        top: 110px;
    }
    .whatsNew-cloud p.item-11 {
        font-size: 20px;
        left: 70%;
        top: 280px;
    }
    .whatsNew-cloud p.item-12 {
        font-size: 30px;
        left: 6%;
        top: 242px;
    }
    .whatsNew-cloud p.item-13 {
        left: 62%;
        top: 161px;
    }
    .whatsNew-cloud p.item-14 {
        top: 190px;
        font-size: 40px;
    }
    .whatsNew-cloud p.item-15 {
        top: 310px;
        font-size: 20px;
        left: 75%;
    }
    .whatsNew-cloud p.item-16 {
        top: 330px;
    }
    .whatsNew-cloud p.item-17 {
        top: 293px;
        left: 0;
    }
    .whatsNew-cloud p.item-19 {
        top: 280px;
        font-size: 40px;
    }

    .whatsNew-cloud {
        height: 380px;
    }
}
@media only screen and (max-width: 640px) {
    .whatsNew-cloud p.item-1 {
        font-size: 25px;
        left: 0;
    }
    .whatsNew-cloud p.item-2 {
        left: 65%;
        top: 36px;
        font-size: 25px;
    }
    .whatsNew-cloud p.item-3 {
        top: 90px;
    }
    .whatsNew-cloud p.item-4 {
        left: 78%;
        top: -10px;
    }
    .whatsNew-cloud p.item-5 {
        top: 160px;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 45px;
        left: 10%;
        top: 30px;
    }
    .whatsNew-cloud p.item-7 {
        font-size: 22px;
        top: 126px;
    }
    .whatsNew-cloud p.item-8 {
        left: 50%;
        font-size: 22px;
    }
    .whatsNew-cloud p.item-9 {
        left: 60%;
        top: 126px;
    }
    .whatsNew-cloud p.item-10 {
        top: 240px;
        left: 20px;
        font-size: 22px;
    }
    .whatsNew-cloud p.item-11 {
        left: 66%;
        top: 233px;
    }
    .whatsNew-cloud p.item-12 {
        font-size: 16px;
        left: 0%;
        top: 312px;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 21px;
        top: 275px;
        left: 0;
    }
    .whatsNew-cloud p.item-14 {
        font-size: 40px;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 22px;
        left: 61%;
        top: 270px;
    }
    .whatsNew-cloud p.item-16 {
        font-size: 26px;
        top: 350px;
    }
    .whatsNew-cloud p.item-17 {
        top: 333px;
        left: unset;
        right: 0;
        font-size: 16px;
    }
    .whatsNew-cloud p.item-18 {
        top: 74px;
        font-size: 40px;
        font-weight: 500;
    }
    .whatsNew-cloud p.item-19 {
        top: 270px;
        left: 40%;
        font-size: 30px;
    }
}
@media only screen and (max-width: 450px) {
    .whatsNew-cloud p.item-1 {
        font-size: 16px;
    }

    .whatsNew-cloud p.item-2 {
        left: unset;
        top: 25px;
        right: 0;
    }

    .whatsNew-cloud p.item-3 {
        left: 0%;
        top: 69px;
        font-size: 14px;
    }

    .whatsNew-cloud p.item-4 {
        left: unset;
        right: 0;
        font-size: 30px;
    }

    .whatsNew-cloud p.item-5 {
        font-size: 24px;
        left: 0;
        top: 120px;
    }

    .whatsNew-cloud p.item-6 {
        font-size: 35px;
        left: 3%;
        top: 20px;
    }

    .whatsNew-cloud p.item-7 {
        font-size: 15px;
        top: 92px;
    }

    .whatsNew-cloud p.item-8 {
        left: unset;
        right: 0;
        font-size: 17px;
        top: 155px;
    }

    .whatsNew-cloud p.item-9 {
        font-size: 19px;
        left: unset;
        top: 97px;
        right: 0;
    }

    .whatsNew-cloud p.item-10 {
        top: 187px;
        font-size: 20px;
    }

    .whatsNew-cloud p.item-11 {
        left: 10px;
        top: 235px;
    }

    .whatsNew-cloud p.item-12 {
        top: 269px;
    }

    .whatsNew-cloud p.item-13 {
        font-size: 16px;
        top: 214px;
    }

    .whatsNew-cloud p.item-14 {
        font-size: 30px;
        top: 150px;
    }

    .whatsNew-cloud p.item-15 {
        font-size: 20px;
        left: 47%;
        top: 211px;
    }

    .whatsNew-cloud p.item-16 {
        font-size: 18px;
        top: 297px;
    }

    .whatsNew-cloud p.item-17 {
        top: 243px;
        font-size: 11px;
    }

    .whatsNew-cloud p.item-18 {
        top: 54px;
        font-size: 30px;
        left: unset;
        right: 0;
    }

    .whatsNew-cloud p.item-19 {
        top: 181px;
        left: unset;
        right: 0;
        font-size: 20px;
    }

    .whatsNew-cloud {
        height: 325px;
    }
}
