.workshops_block {
    display: flex;
    justify-content: center;/*two*/
}
.workshops_item{
    width: calc(100% / 2);
    min-width: 700px; /*two*/
    background: #000;
}
.workshops_item-title_wrap{
    display: flex;
    align-items: center;
    background: rgb(115, 249, 188);
}
.workshops_item-title{
    width: 37%;
    text-align: center;
    font-weight: 900;
    font-size: 38px;
    color: #171616;
}
.workshops_item-date{
    width: 63%;
    font-size: 28px;
    color: #171616;
}
.workshops_item-info_wrap{
    display: flex;
    align-items: stretch;
    /* height: 210px; */
}
/* bottom left */
.workshops_item-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-end ;
    width: 37%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;
    padding: 10px;
}
.workshops_item-info:before{
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
}
.workshops_item_name{
    font-size: 20px;
    font-weight: 900;
}
.workshops_item_position{
    font-size: 18px;
    margin-bottom: 10px;
}
.workshops_social_link{
    display: flex;
}
.workshops_social_link a:nth-child(2n){
    margin:0 10px;
}
/* bottom right */
.workshops_item-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 63%;
    padding: 10px;
}
.workshops_title{
    font-weight: 500;
    font-size: 24px;
}
.workshops_buttons{
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
}
.workshops_button-book{
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 8px 30px;
    font-size: 20px;
    color: #171616;
    background: rgb(115, 249, 188);
}
.workshops_button-learnMore{
    font-size: 20px;
    text-decoration: underline;
}
.workshops_button-learnMore {
    text-decoration: none;
    background: #fff;
}
@media screen and (max-width: 900px) {
    .workshops_item {
        width: 100%;
        margin-bottom: 30px;
    }
    .workshops_item-title{
        font-size: 24px;
    }
    .workshops_item-date{
        font-size: 18px;
    }
    .workshops_item_name{
        font-size: 18px;
    }
    .workshops_item_position{
        font-size: 14px;
    }
    .workshops_title{
        font-size: 14px;
        margin: 10px 0;
    }
    .workshops_buttons {
        /* flex-direction: column; two */
        width: 100%;
        /* align-items: baseline; */
    }

    .workshops_item{
        width: 100%;
        min-width: unset;
    }
}

@media screen and (max-width: 640px) {
    .workshops_buttons {
        flex-direction: column; 
        align-items: baseline;
        margin-top: 30px;
    }

    .workshops_button-book:first-child {
        margin-bottom: 10px;
    }

    .workshops_button-book {
        max-width: 300px;
        width: 100%;
        padding: 5px 15px;
    }

    .workshops_item-info {
        width: 50%;
    }
}
