.openMenu {
    position: fixed;
    width: 100%;
    background-color: #171616;
    height: fit-content;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
    color: #fff;
    padding: 10px 20px 20px;
}
.openMenu ul {
    margin-left: 0;
}
.openMenu ul li {
    list-style-type: none;
    text-align: center;
    font-size: 20px;
    margin: 0;
}
.openMenu ul li a {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    transition: .4s;
    color: #fff;
}
.openMenu ul li a:hover {
    color: #73f9bc;
}
.openMenu img {
    width: 40px;
    height: 40px;
    margin-left: auto;
}

.openMenu li a {
    height: 38px!important;
}

.openMenu li {
    margin-top: 10px!important;
}
